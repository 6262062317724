import React, { useState, useEffect, useRef } from "react";
import ThemeToggle from "./ThemeToggle";
import "./App.css";

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

function App() {
  const textAreaRef = useRef(null);
  const [theme, setTheme] = useState("dark");
  const [text, setText] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(180);
  const [textLocked, setTextLocked] = useState(false);
  const [typingStarted, setTypingStarted] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [typingPaused, setTypingPaused] = useState(false);
  const [email, setEmail] = useState(""); // New state for email
  const [showEmailPrompt, setShowEmailPrompt] = useState(false); // New state for email prompt status
  const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  const placeholderTextDesktop = "Start typing... Press ESC key at any time to pause.";
  const placeholderTextMobile = "Start typing... Press PAUSE/RESUME button at any time to pause.";


  useEffect(() => {
    fetch("https://texttempoapi.fly.dev/prompt")
      .then((response) => response.json())
      .then((data) => setPrompt(data.prompt));
  }, []);

  useEffect(() => {
    if (timeRemaining === 0) {
      setTextLocked(true);
      setShowEmailPrompt(true); // Show email prompt when time runs out
    }
  }, [timeRemaining]);

  useEffect(() => {
    if (typingStarted && !typingPaused) {
        textAreaRef.current.focus();
    }
}, [typingStarted, typingPaused]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setTypingPaused((prevTypingPaused) => !prevTypingPaused);
      } else if (typingPaused) {
        setTypingPaused(false);
      } else if (!typingStarted) {
        setTypingStarted(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [typingPaused, typingStarted]);

  useEffect(() => {
    let interval;
    if (typingStarted && !typingPaused) {
      interval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(interval);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [typingStarted, typingPaused]);

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleButtonClick = () => {
    if (typingStarted) {
      setTypingPaused(!typingPaused);
    } else {
      setTypingStarted(true);
    }
  };

  const sendEmail = async () => {
    try {
      await fetch('https://texttempoemail.fly.dev/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, prompt, text }),
      });
      setShowEmailPrompt(false); // Hide the email prompt after sending the email
      window.location.reload(); // Reload the page
    } catch (error) {
      console.error('Failed to send email:', error);
    }
  };

  function Footer() {
    return (
      <footer className="app-footer">
        <p>https://blond.dev</p>
      </footer>
    );
  }

  return (
    <div className={`app ${theme}`}>
      <ThemeToggle
        theme={theme}
        toggleTheme={() =>
          setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"))
        }
      />
      {(!typingStarted || typingPaused) && (
        <div className="overlay">
          <div className="prompt-container">
            <p className="prompt-title">Prompt:</p>
            <p className="prompt">{prompt}</p>
            <p className="start-typing">
              {isMobile
                ? "Start typing to begin... Press PAUSE/RESUME button at any time to pause."
                : "Start typing to begin... Press ESC key at any time to pause."}
            </p>
          </div>
        </div>
      )}
      <div className="timer-container">
        <span className={`timer ${theme}`}>{formatTime(timeRemaining)}</span>
      </div>
      <textarea
        ref={textAreaRef}
        className={`user-input ${theme}`}
        placeholder={isMobile ? placeholderTextMobile : placeholderTextDesktop}
        onChange={handleChange}
        value={text}
        disabled={textLocked}
      />
      {isMobile && (
        <button className="start-button" onClick={handleButtonClick}>
          {typingStarted ? (typingPaused ? "Resume" : "Pause") : "Start"}
        </button>
      )}

      {showEmailPrompt && (
        <div className="email-prompt-backdrop">
          <div className="email-prompt">
            <p>Enter Your Email to Receive Your Reponse.</p>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              className="email-input"
              placeholder="Enter email here..."
            />
            <button className="email-submit" onClick={sendEmail}>
              Submit
            </button>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default App;

import React from 'react';

function ThemeToggle({ toggleTheme, theme }) {
  const emoji = theme === 'dark' ? '🌞' : '🌚';

  return (
    <div 
      className="theme-toggle"
      onClick={toggleTheme} 
    >
      {emoji}
    </div>
  );
}

export default ThemeToggle;
